import { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Box, Dialog } from '@mui/material';
// Components
import { AppBar, Drawer, UCBanner } from './components/index';
import InfoPage from '../Pages/InfoPage/InfoPage';
import { FeatureFlagModal, ModalBody, NotificationModals } from '../common';
// Hooks
import { useAuth } from '@/hooks/useAuth';
import { useAppInfo } from '@/providers/AppInfoProvider';
import { useIsOnline } from '@/hooks/useIsOnline';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useShowFeatureFlags } from '@/hooks/useShowFeatureFlags';
// Utils
import { brand } from '@/brand/brand';
import { formatPathName } from '@/utils/index';
// Styles & Images
import SmartWallet from '@/assets/images/SmartWallet.png';

export function Layout({ children }) {
  const router = useRouter();
  const mobile = useIsMobile(520);
  const tablet = useIsMobile(820);
  const isOnline = useIsOnline();
  const { user, isIdleModalShowing } = useAuth();
  const { handleSessionModalClose, notificationModals } = useAppInfo();
  const { showFeatureFlag, handleCloseFeatureFlag } = useShowFeatureFlags();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [layout, setLayout] = useState({
    device: '',
    variant: '',
  });

  useEffect(() => {
    // numbers correspond to device
    // 1 = mobile, 2 = tablet, 3 = desktop
    const currentDevice = mobile ? 1 : tablet ? 2 : 3;
    const currentVariant =
      !user.accessToken && !router.asPath.includes('/store')
        ? 'hide'
        : !user.accessToken
          ? 'unauthorized'
          : router.asPath.includes('/checkout') || router.asPath.includes('/agreements')
            ? 'hide'
            : 'standard';

    setLayout({
      device: currentDevice,
      variant: currentVariant,
    });
    // Closes drawer if resizing to mobile
    if (currentDevice < 3) {
      setIsDrawerOpen(false);
    }
  }, [user, router.asPath, tablet, mobile]);

  const showModals = Object.values(notificationModals).filter((modal) => {
    return modal.show;
  });

  const handleToggleDrawer = () => setIsDrawerOpen((value) => !value);

  // To hide under construction banner, set brand.previousSiteURL to falsy, or remove altogether
  const showUCBanner = !!brand?.previousSiteURL;

  const browserTabTitle = formatPathName(router.asPath);

  return (
    <>
      <Head>
        <meta property="og:title" content={`${brand.brandName} ${browserTabTitle}`} />
        <meta property="og:image" content={`${SmartWallet.src}`} />
        <meta
          property="og:description"
          content={`${brand.brandName} ${browserTabTitle}`}
        />
        <meta property="og:url" content={`${brand.appUrl}${router.asPath}`} />
        <meta name="description" content={`${brand.brandName} ${browserTabTitle}`} />
        <title>{`${brand.brandName} - ${browserTabTitle}`}</title>
      </Head>
      <Box sx={{ display: 'flex' }}>
        <AppBar
          layout={layout}
          isDrawerOpen={isDrawerOpen}
          handleToggleDrawer={handleToggleDrawer}
        />
        <Drawer
          layout={layout}
          isDrawerOpen={isDrawerOpen}
          handleToggleDrawer={handleToggleDrawer}
        />
        <Box
          sx={{
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            minHeight: '100vh',
            paddingTop:
              (layout.device <= 2 && layout.variant !== 'hide') ||
              layout.variant === 'unauthorized'
                ? 'var(--app-bar-height)'
                : '0px',
          }}
        >
          {showUCBanner && <UCBanner prevSiteURL={brand.previousSiteURL} />}
          {isOnline ? (
            children
          ) : (
            <InfoPage
              headerText={brand.pages.errorPages.infoPageOffline.title}
              subText1={brand.pages.errorPages.infoPageOffline.subText}
              // must cache image prior to sending to render
              imageSource={brand.pages.errorPages.infoPageOffline.image}
            />
          )}
        </Box>
        <Dialog open={isIdleModalShowing} onClose={handleSessionModalClose}>
          <ModalBody
            showClose
            handleClose={handleSessionModalClose}
            header="Session Expired"
            body="Your session has expired due to inactivity. Please log in to continue."
            severity="info"
          />
        </Dialog>
        {showModals.length && user.uid ? (
          <NotificationModals modalData={showModals[0]} />
        ) : null}
        <Dialog open={showFeatureFlag} onClose={handleCloseFeatureFlag}>
          <FeatureFlagModal handleClose={handleCloseFeatureFlag} />
        </Dialog>
      </Box>
    </>
  );
}
