import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { blue, green, orange, red, grey } from '@mui/material/colors';
// Config
import { colors } from './colors';
import { typography } from './typography';
import { buttonVariants } from './buttonVariants';

// ! This is the main theme file, where you can override the default MUI theme
// ! You can also create a new theme file and import them here
const theme = extendTheme({
  components: {
    // ***************************** Alert *****************************
    MuiAlert: {
      styleOverrides: {
        root: {
          marginBottom: '16px',
        },
      },
      variants: [
        {
          props: { variant: 'outlined', severity: 'error' },
          style: {
            backgroundColor: red[50],
          },
        },
        {
          props: { variant: 'outlined', severity: 'info' },
          style: {
            backgroundColor: blue[50],
          },
        },
        {
          props: { variant: 'outlined', severity: 'warning' },
          style: {
            backgroundColor: orange[50],
          },
        },
        {
          props: { variant: 'outlined', severity: 'success' },
          style: {
            backgroundColor: green[50],
          },
        },
      ],
    },
    // ***************************** Button *****************************
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Lato, sans-serif',
          fontSize: '16px',
          fontWeight: 600,
          // borderRadius: '16px'
        },
      },
      variants: buttonVariants, // Import from ./buttonVariants.js
    },
    // ***************************** ButtonGroup *****************************
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Lato, sans-serif',
          fontSize: '16px',
          fontWeight: 600,
        },
      },
    },
    // ***************************** Dialog *****************************
    MuiDialog: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(3px)',
        },
        paper: {
          borderRadius: '8px',
          padding: '24px',
        },
      },
    },
    // ***************************** Divider *****************************
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: grey[300],
        },
      },
      variants: [
        {
          props: { variant: 'mediumGrey' },
          style: {
            borderColor: grey[400],
          },
        },
      ],
    },
    // ***************************** FormHelperText *****************************
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '.75rem', // 12px
        },
      },
    },
    // ***************************** Input *****************************
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          fontFamily: 'Lato, sans-serif',
          fontSize: '16px',
          fontWeight: 600,
          color: grey[500],
          '&.quantityField': {
            border: `1px solid ${grey[400]}`,
            borderRight: 'none',
            borderLeft: 'none',
          },
        },
      },
    },
    // ***************************** Paper *****************************
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.surfaces.layerTwo,
          padding: '24px',
        },
      },
    },
    // ***************************** Radio *****************************
    MuiRadio: {
      styleOverrides: {
        root: {
          width: '16px',
          height: '16px',
          border: `1px solid ${colors.surfaces.layerTwoBorder}`,
          '&:hover': {
            border: `1px solid #156280`,
          },
        },
      },
    },
    // ***************************** Tab *****************************
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    // ***************************** TextField *****************************
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&:focus': {
            backgroundColor: 'transparent',
          },
          '&:active': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    // ***************************** Tooltip *****************************
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
        arrow: true,
      },
    },
  },
  colorSchemes: {
    light: {
      palette: {
        ...colors,
      },
    },
  },
  typography: {
    ...typography,
  },
});

export { colors, typography };

export default theme;
