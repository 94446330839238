import { useEffect, useRef, useState } from 'react';
import {
  Button,
  IconButton,
  Switch,
  FormControlLabel,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

export default function FeatureFlagModal({ handleClose }) {
  const [allFeatureFlags, setAllFeatureFlags] = useState([]);
  const inputRef = useRef(null);
  const focusChild = () => {
    inputRef.current && inputRef.current.focus();
  };

  const setFeatureFlags = () => {
    const featureFlags = JSON.parse(localStorage.getItem('core-feature-flags') ?? '{}');
    const flagItems = Object.entries(featureFlags).map(([key, value]) => ({
      key,
      value,
    }));
    const sorted = flagItems.sort((a, b) => {
      if (a.key < b.key) {
        return -1;
      }
      if (a.key > b.key) {
        return 1;
      }
      return 0;
    });
    setAllFeatureFlags(sorted);
  };

  const deleteFeatureFlag = (key) => {
    const flags = JSON.parse(localStorage.getItem('core-feature-flags') ?? '{}');
    delete flags[key];
    localStorage.setItem('core-feature-flags', JSON.stringify(flags));
    setFeatureFlags();
  };

  useEffect(() => {
    setFeatureFlags();
  }, []);

  const handleCheck = (e, key) => {
    setAllFeatureFlags((prevState) => {
      return prevState.map((item) => {
        if (item.key === key) {
          const flags = JSON.parse(localStorage.getItem('core-feature-flags'));
          flags[key] = e.target.checked;
          localStorage.setItem('core-feature-flags', JSON.stringify(flags));
          return {
            key,
            value: e.target.checked,
          };
        }
        return item;
      });
    });
  };

  const handleSubmit = () => {
    const coreFeatureFlags = localStorage.getItem('core-feature-flags');
    if (coreFeatureFlags) {
      const flags = JSON.parse(coreFeatureFlags);
      flags[inputRef.current.value] = false;
      localStorage.setItem('core-feature-flags', JSON.stringify(flags));
    } else {
      localStorage.setItem(
        'core-feature-flags',
        JSON.stringify({ [inputRef.current.value]: false })
      );
    }
    inputRef.current.value = '';
    inputRef.current.focus();
    setFeatureFlags();
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '500px', position: 'relative' }}>
      <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIcon color="grey" />
      </IconButton>
      <Typography variant="h2" sx={{ my: 2 }}>
        Feature Flags
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, gap: 2 }}>
        <div htmlFor="flag" onClick={focusChild} style={{ width: '100%' }}>
          <TextField
            size="small"
            id="flag"
            name="flag"
            type="text"
            inputRef={inputRef}
            fullWidth
          />
        </div>
        <Button size="small" variant="contained" onClick={handleSubmit}>
          Add
        </Button>
      </Box>
      <Box>
        {allFeatureFlags.map((flag) => (
          <Box
            key={flag.key}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginRight: '8px',
            }}
          >
            <FormControlLabel
              label={flag.key}
              labelPlacement="end"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: 'max-content',
              }}
              control={
                <Switch
                  name={`toggle-${flag.key}`}
                  id={`toggle-${flag.key}`}
                  onChange={(e) => handleCheck(e, flag.key)}
                  checked={flag.value}
                />
              }
            />
            <IconButton onClick={() => deleteFeatureFlag(flag.key)}>
              <DeleteIcon color="grey" fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
